<template>
  <div class="main" style="background-image: url('./images/back.svg')">
    <div class="wrap">
      <div class="main__greetings">
        <span>Привет!</span>
      </div>
      <div class="main__container">
          <p>
          <span
            class="text"
          >Твой след – это эко-портал, на котором ты сможешь узнать больше о том, что
          такое углеродный след и узнать какой след оставляешь именно ты! Углеродный
          след — это показатель общих выбросов CO2 и других парниковых газов от
          ваших повседневных действий, которые влияют на нашу планету. Ответь на
          несколько вопросов, с помощью нашего калькулятора, чтобы узнать чуть
          больше о себе.
          </span>
            <base-icon name="earth" class="main__container--earth" />
          </p>
      </div>
      <div class="main__actions">
        <carbon-button
          text="Посчитать след"
          :vue-bind="{ color: '#4CAF50' }"
          @click="calc"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CarbonButton from '@components/CarbonButton';

export default {
  name: 'Main',
  components: {
    CarbonButton,
  },
  methods: {
    calc() {
      this.$store.commit('SET_GROUP', 1);
      this.$router.push('/calc');
    },
  },
};
</script>
<style lang="sass">
  .main
    background-size: cover
    padding: 0 25px 25px 25px
    .wrap
      flex-direction: column
    .main__greetings
      font-style: normal
      font-weight: bold
      font-size: 72px
      line-height: 88px
      color: #4caf50
      margin-bottom: 25px
    .main__container
      p
        display: flex
        span, svg
          flex-basis: 100%
      &--earth
        float: right
        height: 400px
        max-width: 500px
        margin-top: -35px
  @media only screen and (max-width: 768px)
    .main
      .main__container
        p
          flex-direction: column
        &--earth
          margin-top: 0
      .main__actions
        display: flex
        justify-content: center
</style>
